.articleContainer {
  overflow: auto;
}

.articleCard {
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  // min-width: 301px;

  button {
    border: 0;
  }
}

.articleImage {
  max-width: 120px;
  width: 40%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  aspect-ratio: 1 / 1;
  height: min-content;
}

.li {
  padding-top: 24px;
  padding-left: 1rem;
  li:not(:last-child) {
    /* Aquí van los estilos que deseas aplicar */
    margin-bottom: 1.5rem;
  }
}

.links {
  &:hover {
    text-decoration: underline;
  }
}

.accordion {
  padding-left: 0;
  padding-right: 0;
  .header {
    padding-left: 1rem;
    padding-right: 1rem;
    img {
      right: 1rem;
    }
  }
}

.twoLines {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limita a 2 líneas */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noPadding {
  padding: 0;
  height: auto;
}

@media screen and (min-width: 1024px) {
  .container {
    box-shadow: 0px -10px 20px 10px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    width: 100vw;
  }

  .li {
    padding-top: 0;
    li:not(:last-child) {
      /* Aquí van los estilos que deseas aplicar */
      margin-bottom: 0.25rem;
    }
  }
}

.slideDown {
  z-index: 1;
  animation: sslideDown 0.1s ease-out forwards;
}

.slideUp {
  z-index: 1;
  animation: sslideUp 0.1s ease-out forwards;
}

/* Keyframes para la animación */
@keyframes sslideDown {
  0% {
    position: absolute;
    top: -300px;
  }
  100% {
    position: absolute;
    top: 0;
  }
}

@keyframes sslideUp {
  0% {
    position: absolute;
    top: 0;
  }
  100% {
    position: absolute;
    top: -300px;
  }
}
