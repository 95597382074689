@import "../../../assets/styles/breakpoints";
@import "../../../assets/styles/constants";
@import "../../../assets/styles/mixins";

.root {
  margin-top: var(--space-400);
  @include up("md") {
    margin-top: 0;
  }
  .sectionDescription {
    background-color: #00405e;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 50%;
    padding: 0 0 2rem 0;
    @include up("md") {
      background-image: url("../../../assets/images/security.png");
      padding: 2rem 0;
    }
    img {
      width: 100%;
      @include up("md") {
        display: none;
      }
    }
    .description {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: var(--space-300) var(--space-200);
      padding: 0;
      @include up("md") {
        margin: 2rem auto;
      }
      .text {
        padding-right: 5rem;
        box-sizing: border-box;
        @include up("md") {
          width: 50%;
        }
        .toggle {
          display: flex;
          align-items: center;
          @include arrow-icon-after;
        }
        .isExpanded {
          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .info {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 0 0;
    position: relative;
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: 0.7s;
    padding: 0;
    @include up("md") {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
      gap: 0 2.4rem;
    }
    .item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      background-color: $color-background-primary;
      @include up("md") {
        border-radius: 0.8rem;
      }
      .imgContent {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 2.4rem;
        width: 100%;
        box-sizing: border-box;
        @include up("md") {
          border-radius: 0.8rem 0.8rem 0 0;
          justify-content: center;
        }
        img {
          width: 45px;
        }
      }
      .textContent {
        padding: 2.4rem;
        justify-content: flex-start;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        box-sizing: border-box;
        .title {
          font-weight: bold;
          margin-bottom: 1.6rem;
        }
        .text {
          font-size: 1.2rem;
        }
      }
    }
    .closeBtn {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .show {
    // display: flex !important;
    height: auto;
    opacity: 1;
    overflow: auto;
  }
}

.neutralBg {
  background-color: $color-text-form;
}
.primaryBg {
  background-color: $color-primary;
}
