.VirtualButton {
  max-width: 100%;  
  background-color: var(--mn-neutral-three);
}
.VirtualButton span {  
  color: var(--mn-text-primary) !important;
}
@media screen and (max-width: 639px) {
  .VirtualButton {
    font-size: var(--mn-body2-lh);
    height: 2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.btn:hover {
  opacity: 1;
}

.Item {
  height: 56px;
  padding: 1.25rem 0rem;
}

.buttonSubmenu>span {
  font-weight: normal;
}

.submenuElement {
  font-size: 1rem;
  color: white;
}



@media screen and (min-width: 768px) {
  .VirtualButton {
    max-width: max-content;
  }

  .Item {
    height: 60px;
    padding: 1.25rem 1rem;
  }

  .buttonSubmenu {
    width: 180px;
  }

  .buttonSubmenu>span {
    width: inherit;
  }

  .navDropdown [data-testid="container-tabs"]:first-child {
    min-height: 377px;
  }

  .navDropdown [data-testid="container-tabs"] [data-testid="content1"] {
    min-height: 318px !important;
    background-color: var(--mn-background-tertiary);
  }

  .navDropdown [data-testid="container-tabs"] [data-testid="content1"] [data-testid="container-tabs"] {
    min-height: 318px !important;
  }

  .navDropdown [data-testid="container-tabs"] [data-testid="container-tabs"] [data-testid="content1"] {
    background-color: var(--mn-background-tertiary);
  }
}

.nav{
  z-index: 51;
}