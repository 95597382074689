$sizes: (
  "sm": 600px,
  "md-tablet": 768px,
  "md": 960px,
  "lg": 1440px,
  "xl": 1920px,
  "xxl": 2500px,
);

$container-max-widths: (
  sm: 580px,
  md-tablet: 720px,
  md: 930px,
  lg: 1360px,
  xl: 1440px,
  xxl: 1440px,
);

$media-queries: (
  "sm": map-get($sizes, "sm"),
  "md-tablet": map-get($sizes, "md-tablet"),
  "md": map-get($sizes, "md"),
  "lg": map-get($sizes, "lg"),
  "xl": map-get($sizes, "xl"),
  "xxl": map-get($sizes, "xxl"),
);

@mixin up($size) {
  @media (min-width: map-get($sizes, $size)) {
    @content;
  }
}
@mixin down($size) {
  @media (max-width: map-get($sizes, $size)) {
    @content;
  }
}
