.line {
  align-self: stretch;
  margin-left: 2rem;
  margin-right: 2rem;
}

.li {
  padding-top: 0;
  padding-left: 1rem;
  li:not(:last-child) {
    /* Aquí van los estilos que deseas aplicar */
    margin-bottom: 0.25rem;
  }
}

.links {
  &:hover {
    text-decoration: underline;
  }

  .tag {
    margin-left: 0.5rem;
    background-color: #20df89;
    padding: 0.25rem 0.5rem;
    &:hover {
      text-decoration: none;
    }
  }
}

.button {
  border-radius: 8px;
  border: 0;
}

.accordion {
  padding-left: 0;
  padding-right: 0;
  .header {
    padding-left: 1rem;
    padding-right: 1rem;
    img {
      right: 1rem;
    }
  }
}

@media screen and (min-width: 1024px) {
  .container {
    box-shadow: 0px -10px 20px 10px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    width: 100vw;
  }

  .li {
    padding-top: 0;
    li:not(:last-child) {
      /* Aquí van los estilos que deseas aplicar */
      margin-bottom: 0.25rem;
    }
  }
}

.slideDown {
  z-index: 1;
  animation: sslideDown 0.1s ease-out forwards;
}

.slideUp {
  z-index: 1;
  animation: sslideUp 0.1s ease-out forwards;
}

/* Keyframes para la animación */
@keyframes sslideDown {
  0% {
    position: absolute;
    top: -300px;
  }
  100% {
    position: absolute;
    top: 0;
  }
}

@keyframes sslideUp {
  0% {
    position: absolute;
    top: 0;
  }
  100% {
    position: absolute;
    top: -300px;
  }
}
