@import "../../assets/styles/breakpoints";
@import "../../assets/styles/constants";
@import "../../assets/styles/mixins";

.hero {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
  img {
    width: 100%;
    @include up("sm") {
      display: none;
    }
  }
  @include up("md-tablet") {
    // margin-top: 0;
    background-image: url("../../assets/images/heroBg.svg");
    background-repeat: no-repeat;
    background-position: right bottom;
  }
}

.main {
  padding: 0;
  @include up("md") {
    // margin-top: 5rem;
    // padding: var(--mn-space-600) 0;
  }
}
