$_timing-function-default: ease-in-out;
$_duration-default: 0.25s;

@mixin flex-center {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin transition-standard(
  $transition-property: null,
  $transition-duration: $_duration-default,
  $transition-timing-function: $_timing-function-default
) {
  @if $transition-property {
    transition-property: $transition-property;
  }
  transition-duration: $transition-duration;
  transition-timing-function: $transition-timing-function;
}

@mixin arrow-icon-after {
  &::after {
    @include transition-standard($transition-property: transform);

    $width: 1.4rem; // 14px

    content: "";
    display: block;
    margin-left: 0.6rem; // 6px
    width: $width;
    height: 1rem; // 10px
    background-image: url("../icons/icon-angle-down-white.svg");
    background-repeat: no-repeat;
    background-size: $width auto;
  }

  &:focus::after {
    background-image: url("../icons/icon-angle-down-yellow.svg");
  }

  &.isExpanded::after {
    transform: rotate(180deg);
  }
}
