// Colors
$color-primary: #ffa800;
$color-secondary: #000b25;
$color-text-primary: #ffffff;
$color-text-secondary: #000000;

$color-text-complementary: #576471;
$color-text-neutral: #97afc7;
$color-text-link: #000000;
$color-text-form: #7d98b1;

$color-status-success: #1da865;
$color-status-warning: #ff8a00;
$color-status-error: #c93532;
$color-status-info: #62aced;
$color-status-neutral: #97afc7;

$color-background-primary: #ffffff;
$color-background-secondary: #f1f4f8;

// Typography
$typography-family-main: "Inter", sans-serif;
$typography-family-headings: "Plantin", "Inter", sans-serif;

$typography-weight-regular: 400;
$typography-weight-medium: 500;
$typography-weight-bold: 700;
