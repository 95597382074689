@import "breakpoints";
@import "constants";
@import "mixins";

html,
body {
  padding: 0;
  margin: 0;
  font-family: $typography-family-main;
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  background-color: $color-background-secondary;
}

.container {
  width: 100%;
  padding: 0 1rem;
  margin: auto;
  display: flex;
  box-sizing: border-box;
}

@each $name, $breakpoint in $media-queries {
  @media screen and (min-width: $breakpoint) {
    .container {
      max-width: map-get($container-max-widths, $name);
      padding: 0 0.75rem;
    }
  }
}

// .mb-1 {
//   margin-bottom: 1rem !important;
// }
// .mb-2 {
//   margin-bottom: 2rem !important;
// }
.mb-3 {
  margin-bottom: 3rem !important;
}
.mb-4 {
  margin-bottom: 2.5rem !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
// .mt-4 {
//   margin-top: 4rem !important;
// }

.bold {
  font-weight: $typography-weight-bold !important;
}
