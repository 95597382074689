.pbCustom {
  padding-bottom: 120px;
}

.scroll {
  overflow-y: auto;
  height: 100vh;
  position: relative;
}

@media screen and (min-width: 768px) {
  .pbCustom {
    padding-bottom: 80px;
  }
}

@media screen and (min-width: 1024px) {
  .top {
    top: 64px;
  }
}

@media screen and (min-width: 1280px) {
  .top {
    top: 90px;
  }
}

.mmSlideDown {
  animation: sslideDown 1.5s ease-out forwards;
  z-index: 1;
}

.mmSlideUp {
  animation: sslideUp 1.5s ease-out forwards;
  z-index: 1;
}

@keyframes sslideDown {
  0% {
    max-height: 0;
    top: -250px !important;

    overflow: hidden;
    transform: translateY(-10px);
  }

  100% {
    max-height: 500px; /* Ajusta según el tamaño máximo esperado */
    opacity: 1;
    top: 90px !important;
    transform: translateY(0);
  }
}

@keyframes sslideUp {
  0% {
    max-height: 500px; /* Ajusta según el tamaño máximo esperado */

    top: 90px !important;
    transform: translateY(0);
  }

  100% {
    max-height: 0;

    top: -250px !important;
    overflow: hidden;
    transform: translateY(-10px);
  }
}
