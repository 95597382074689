.phoneItem {
  margin-top: -16px;
}
.phone {
  text-decoration: underline;
}
@media screen and (min-width: 640px) {
  .phone {
    text-decoration: none;
  }
}

.modalLink {
  cursor: pointer;
}
